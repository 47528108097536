export interface DataLoadedEvent {
	data: unknown;
}

export interface AppErrorEvent {
	message: string;
}

export enum EventType {
	DATA_LOADED = "DataLoaded",
	API_ERROR = "APIErrorOccurred"
}
