import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { RootProvider, App } from "./app";
import "@/styles/index.css";

createRoot(document.getElementById("root")!).render(
	<StrictMode>
		<BrowserRouter>
			<RootProvider>
				<App />
			</RootProvider>
		</BrowserRouter>
	</StrictMode>
);
