import React, { useRef } from "react";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
//internal import
import { AppStore, makeStore } from "@/lib/store";
import { IRootProviderType } from "@/types";
import { LayoutProvider } from "@/contexts/layout.context";

export const RootProvider: React.FC<IRootProviderType> = ({ children }) => {
	const storeRef = useRef<AppStore>();
	if (!storeRef.current) {
		storeRef.current = makeStore();
	}
	const onError = (error: any, info: React.ErrorInfo) => {
		console.log("error", error);
		console.log("info", info);
	};
	const SectionErrorUnknown = () => {
		return <div>Error</div>;
	};
	return (
		<Provider store={storeRef.current}>
			<ErrorBoundary fallback={<SectionErrorUnknown />} onError={onError}>
				<LayoutProvider>
				{children}
				</LayoutProvider>
			</ErrorBoundary>
		</Provider>
	);
};
