import axios, { AxiosError, AxiosRequestConfig } from "axios";

import {
	GetRequestParams,
	PostRequestParams,
	PutRequestParams,
	DeleteRequestParams,
	IConfigAxiosInstance,
	ApiResponseBase,
	Method,
	IAxiosInstance
} from "@/types";
import { getAPIPath } from "../api.support";
import { handleErrorResponse } from "../api.support";
import { HAPPY_MONEY_API_PATHS } from "./happy-money.constant";
import { LocalStorage } from "@/lib/local-storage";

//#region private action
const axios_hm_instance = axios.create({});

// interceptor for http
axios_hm_instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error instanceof AxiosError) {
			const axiosError = error as AxiosError;
			return Promise.reject(axiosError);
		}
		return Promise.reject(error || "Wrong Services");
	}
);

axios_hm_instance.interceptors.request.use((config: any) => {
	const token = LocalStorage.get("onapp-mkp-sid");
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	} else {
		throw new Error("Token invalid");
	}
	return config;
});

function setConfig(config: IConfigAxiosInstance) {
	if (config.params) axios_hm_instance.defaults.params = config.params;
	if (config.timeout) axios_hm_instance.defaults.timeout = config.timeout;
	if (config.baseURL) axios_hm_instance.defaults.baseURL = config.baseURL;
}
//#endregion

//#region public action
async function callGetRequest<T>(args: GetRequestParams): Promise<ApiResponseBase<T>> {
	try {
		const _path: string = getAPIPath(HAPPY_MONEY_API_PATHS, {
			http_method: Method.Get,
			operation: args.operation,
			urlIds: args.urlIds
		});
		const option: AxiosRequestConfig = {
			url: _path + (args.customQuery ? `?${args.customQuery}` : ""),
			method: Method.Get,
			headers: args.extraHeaders,
			params: args.query ?? null,
			timeout: args.timeout
		};

		const response = await axios_hm_instance.request(option);
		return response.data as ApiResponseBase<T>;
	} catch (err: any) {
		console.error(err);
		return handleErrorResponse(err);
	}
}

async function callPostRequest<T>(args: PostRequestParams): Promise<ApiResponseBase<T>> {
	try {
		const _path: string = getAPIPath(HAPPY_MONEY_API_PATHS, {
			http_method: Method.Post,
			operation: args.operation,
			urlIds: args.urlIds
		});
		const option: AxiosRequestConfig = {
			url: _path + (args.customQuery ? `?${args.customQuery}` : ""),
			method: Method.Post,
			headers: args.extraHeaders,
			params: args.query ?? null,
			data: args.data,
			timeout: args.timeout
		};

		const response = await axios_hm_instance.request(option);
		return response.data as ApiResponseBase<T>;
	} catch (err: any) {
		console.error(err);
		return handleErrorResponse(err);
	}
}

async function callPutRequest<T>(args: PutRequestParams): Promise<ApiResponseBase<T>> {
	try {
		const _path: string = getAPIPath(HAPPY_MONEY_API_PATHS, {
			http_method: Method.Put,
			operation: args.operation,
			urlIds: args.urlIds
		});
		const option: AxiosRequestConfig = {
			url: _path + (args.customQuery ? `?${args.customQuery}` : ""),
			method: Method.Put,
			headers: args.extraHeaders,
			params: args.query ?? null,
			data: args.data,
			timeout: args.timeout
		};
		const response = await axios_hm_instance.request(option);
		return response.data as ApiResponseBase<T>;
	} catch (err: any) {
		console.error(err);
		return handleErrorResponse(err);
	}
}

async function callDeleteRequest<T>(args: DeleteRequestParams): Promise<ApiResponseBase<T>> {
	try {
		const _path: string = getAPIPath(HAPPY_MONEY_API_PATHS, {
			http_method: Method.Delete,
			operation: args.operation,
			urlIds: args.urlIds
		});
		const option: AxiosRequestConfig = {
			url: _path + (args.customQuery ? `?${args.customQuery}` : ""),
			method: Method.Delete,
			headers: args.extraHeaders,
			params: args.query ?? null,
			timeout: args.timeout
		};
		const response = await axios_hm_instance.request(option);
		return response.data as ApiResponseBase<T>;
	} catch (err: any) {
		console.error(err);
		return handleErrorResponse(err);
	}
}
//#endregion

export const AXIOS_HAPPY_MONEY: IAxiosInstance = {
	GET: callGetRequest,
	POST: callPostRequest,
	PUT: callPutRequest,
	DELETE: callDeleteRequest,
	set: setConfig
};
