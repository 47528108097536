import { META_ENV } from "@/configs";
import { AXIOS_HAPPY_MONEY } from "./happy-money.base";
import { LedgerAPI } from "./v2024/ledger.api";
// import { TicketAPI } from "./v2024/ticket.api";

export class HappyMoneyAPI {
	ledger: typeof LedgerAPI;
	// ticket: typeof TicketAPI;

	constructor() {
		this.setAxiosInstance();
		this.ledger = { ...LedgerAPI };
		// this.ticket = { ...TicketAPI };
	}

	private setAxiosInstance() {
		AXIOS_HAPPY_MONEY.set({
			baseURL: META_ENV.env.VITE_API_URL,
			timeout: 10000
		});
	}
}
