import EventEmitter from "eventemitter3";
import { AppErrorEvent, DataLoadedEvent, EventType } from "@/types";

const AppEvent = new EventEmitter();

const onErrorOccurred = (error: AppErrorEvent) => {
	// Handle error
	console.log("Error occurred:", error);
	window.alert(error.message);
};

// #region event emitter
const onDataLoaded = (arg: DataLoadedEvent) => {
	// Handle loaded data
	console.log("Data loaded:", arg);
};

//#endregion
AppEvent.on(EventType.DATA_LOADED, onDataLoaded);

AppEvent.on(EventType.API_ERROR, onErrorOccurred);

export { AppEvent };
