import { Routes, Route } from "react-router-dom";
import { AuthRoutes } from "./auth-routes";
import { ExceptionRoutes } from "./exception-routes";
import { IntroRoutes } from "./intro-routes";
import { AppRoutes } from "./app-routes";
import { AppLayout, MinimalLayout } from "@/layouts";

const RootRoutes: React.FC = () => {
	return (
		<Routes>
			{/* Intro Routes */}
			<Route element={<MinimalLayout />}>
				{IntroRoutes.map((route, index) => (
					<Route key={index} path={route.path} element={route.element} />
				))}
			</Route>
			{/* App Routes */}
			<Route element={<AppLayout />}>
				{AppRoutes.map((route, index) => (
					<Route key={index} path={route.path} element={route.element} />
				))}
			</Route>
			{/* Auth Routes */}
			<Route element={<MinimalLayout />}>
				{AuthRoutes.map((route, index) => (
					<Route key={index} path={route.path} element={route.element} />
				))}
			</Route>
			{/* Exception Routes */}
			<Route element={<MinimalLayout />}>
				{ExceptionRoutes.map((route, index) => (
					<Route key={index} path={route.path} element={route.element} />
				))}
			</Route>
			<Route path="*" element={<div>404</div>} />
		</Routes>
	);
};

export default RootRoutes;
