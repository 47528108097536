import { createAsyncThunk } from "@reduxjs/toolkit";
//internal import
import { HappyMoneyAPI, GetLedgerRequest, PostLedgerRequest, GetListLedgerRequest, GetListLedgerResponse } from "@/services";

import { LEDGER_TYPE } from "./ledger.constant";
import { Ledger } from "@/types";

export const getListLedger = createAsyncThunk<GetListLedgerResponse, GetListLedgerRequest>(
	LEDGER_TYPE.GET_LIST_LEDGER,
	async (_arg: GetListLedgerRequest, thunkAPI) => {
		try {
			const result = await new HappyMoneyAPI().ledger.getList(_arg);
			console.log(result);
			return result;
		} catch (e: unknown) {
			return thunkAPI.rejectWithValue(e);
		}
	}
);

export const getLedger = createAsyncThunk<Ledger, GetLedgerRequest>(LEDGER_TYPE.GET_LEDGER, async (_arg: GetLedgerRequest, thunkAPI) => {
	try {
		const result = await new HappyMoneyAPI().ledger.get({ id: _arg.id });
		return result;
	} catch (e: unknown) {
		return thunkAPI.rejectWithValue(e);
	}
});

export const addNewLedger = createAsyncThunk<string, PostLedgerRequest>(LEDGER_TYPE.ADD_NEW_LEDGER, async (_arg: PostLedgerRequest, thunkAPI) => {
	try {
		const result = await new HappyMoneyAPI().ledger.create(_arg);
		return result;
	} catch (e: unknown) {
		return thunkAPI.rejectWithValue(e);
	}
});

export const enableLedger = createAsyncThunk<boolean, GetLedgerRequest>(LEDGER_TYPE.ENABLE_LEDGER, async (_arg: GetLedgerRequest, thunkAPI) => {
	try {
		const result = await new HappyMoneyAPI().ledger.enable(_arg);
		return result;
	} catch (e: unknown) {
		return thunkAPI.rejectWithValue(e);
	}
});

export const disableLedger = createAsyncThunk<boolean, GetLedgerRequest>(LEDGER_TYPE.DISABLE_LEDGER, async (_arg: GetLedgerRequest, thunkAPI) => {
	try {
		const result = await new HappyMoneyAPI().ledger.disable(_arg);
		return result;
	} catch (e: unknown) {
		return thunkAPI.rejectWithValue(e);
	}
});
