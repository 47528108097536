import { API_KIND, API_PATH_SEGMENT, API_VERSION } from "@/common";
import { AppErrorEvent, EventType } from "@/types";
import { AppEvent } from "@/configs/event";
import { GetPathArgs, ResourcePath, IdSet } from "@/types/api.type";
import { AxiosError } from "axios";

interface IRoutes {
	kind: API_KIND | string;
	name?: string;
	version?: API_VERSION | string;
	isRoot?: boolean;
}

export const genAPIRoutes = (options: IRoutes): string => {
	const _version: string = options.version ?? "";
	const _kind: string = options.kind;
	const _isRoot: boolean = options.isRoot ?? false;
	const _name: string = options.name ?? "";
	let api_path: string = `/${API_PATH_SEGMENT.API}`;

	if (_version) api_path += `/${_version}`;
	if (_kind) api_path += `/${_kind}`;
	if (_isRoot) return (api_path += `/*`);
	if (_name) api_path += `/${_name}`;
	return api_path;
};

export function getAPIPath(resource: ResourcePath[], options: GetPathArgs): string {
	let match: string | null = null;
	let specificity = -1;
	const { http_method, operation, entity, urlIds } = options;

	resource.forEach((path: ResourcePath) => {
		//error 1st
		if (http_method !== path.http_method || operation !== path.operation || path.ids.length <= specificity) {
			return;
		}
		//mapping id
		let pathUrlIds: IdSet = { ...urlIds };
		path.ids.forEach((id) => {
			if (!pathUrlIds[id] && entity && entity[id]) {
				pathUrlIds[id] = entity[id];
			}
		});

		pathUrlIds = Object.entries(pathUrlIds).reduce((acc: IdSet, [key, value]: [string, string | number | null]) => {
			if (value) acc[key] = value;
			return acc;
		}, {});

		// If we weren't given all of the path's required ids, we can't use it
		const diff = path.ids.reduce((acc: string[], id: string) => (pathUrlIds[id] ? acc : acc.concat(id)), []);
		if (diff.length > 0) return;

		specificity = path.ids.length;

		match = path.path.replace(/(<([^>]+)>)/g, (_m1, _m2, id) => `${pathUrlIds[id]}`);
	});

	if (!match) {
		throw new Error("Could not find a path for request");
	}

	return match;
}

export function handleErrorResponse(error: any) {
	try {
		const axiosError = error as AxiosError;
		if (!axiosError.response) throw axiosError;
		if (axiosError.status === 429) {
			throw new Error("Too Many Requests");
		}
		if (axiosError.status === 400) {
			throw new Error("Bad Request");
		}
		if (axiosError.status === 401) {
			throw new Error("Unauthorized");
		}
		if (axiosError.status === 403) {
			throw new Error("Forbidden");
		}
		if (axiosError.status === 404) {
			throw new Error("Not Found");
		}
		if (axiosError.status === 500) {
			throw new Error("Internal Server Error");
		}
		if (axiosError.status === 408) {
			throw new Error("Request Timeout");
		}

		return axiosError;
	} catch (e: any) {
		sendEventError(e.message);
		return e;
	}
}

function sendEventError(message: string) {
	const errorEvent: AppErrorEvent = { message };
	AppEvent.emit(EventType.API_ERROR, errorEvent);
}
