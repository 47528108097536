/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { RouteObject } from "react-router-dom";
//internal import
import { APP_PATH } from "@/configs";

const Home = React.lazy(() => import("@/screens/apps/app.container"));
const LedgerDetail = React.lazy(() => import("@/screens/ledgers/ledger-detail.container"));

export const AppRoutes: RouteObject[] = [
	{ path: APP_PATH.ROOT_APP, element: <Home /> },
	{ path: APP_PATH.LEDGER.DETAIL, element: <LedgerDetail />, id: "ledger-id" }
];
