import { createAsyncThunk } from "@reduxjs/toolkit";
//internal import
import { LifeCycleResponse, SessionInfoResponse } from "@/types/app.type";
// import { ping_session_api, get_session_api } from "@/services/marketplace";
// import { SettingActions } from "@/features/setting";
import { APP_TYPE } from "./app.constant";

export const pingSession = createAsyncThunk<LifeCycleResponse>(APP_TYPE.CHECK_LIFECYCLE, async (_arg: unknown, thunkAPI) => {
	try {
		// const result = await ping_session_api();
		// return result;
		return {
			success: true,
			message: "pong",
			isAlive: true,
			isLive: true,
			meta: {
				version: "1.0.0",
				build: "1",
				env: "dev"
			}
		};
	} catch (e: unknown) {
		return thunkAPI.rejectWithValue(e);
	}
});

export const getSession = createAsyncThunk<SessionInfoResponse | null>(APP_TYPE.SESSION_INFO, async (_arg: unknown, thunkAPI) => {
	try {
		// const result = await get_session_api();
		// if (result) {
		// get setting if session's living
		// await thunkAPI.dispatch(SettingActions.get());
		// }
		// return result;
		return null;
	} catch (e: unknown) {
		return thunkAPI.rejectWithValue(e);
	}
});
