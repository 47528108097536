// import { Layout01View } from "@/screens/layout-01.view";
import RootRoutes from "@/routes/root-routes";
import "@/styles/App.css";

function App() {
	return (
		<>
			<RootRoutes />
		</>
	);
}

export default App;
