/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState, useEffect } from "react";
import _ from "lodash";
// internal import
import { useIdle, useLocation, useAppSelector } from "@/lib/hook";
import { BreadcrumbProps, ILayoutContextType, NavItemType } from "@/types/layout.type";
import { APP_CONFIG, META_ENV } from "@/configs";
import { TailwindIndicator } from "@/components/tailwind-indicator";
import { AUTH_PATH, EXCEPTION_PATH } from "@/configs/root-path";
import { MENU_ITEMS, SETTING_MENU } from "@/configs/nav";

interface ILayoutContext {
	isReadySideBar: boolean;
	isReadySettingSideBar: boolean;
	isReadyBreadcrumb: boolean;
	isReadyAvatarBox: boolean;
	breadcrumbs: BreadcrumbProps[];
	menuSelected: NavItemType | null;
	setBreadcrumb: (breadcrumb: BreadcrumbProps[]) => void;
	setMenuSelect: (menu: NavItemType) => void;
	setMainScroll: (scroll: { scrollHeight: number; scrollTopHeigh: number; elementHeigh: number }) => void;
	mainScroll: {
		scrollHeight: number;
		scrollTopHeigh: number;
		elementHeigh: number;
	};
}

const initialLayoutContext: ILayoutContext = {
	isReadySideBar: false,
	isReadySettingSideBar: false,
	isReadyBreadcrumb: false,
	isReadyAvatarBox: false,
	breadcrumbs: [],
	menuSelected: null,
	mainScroll: { scrollHeight: 0, scrollTopHeigh: 0, elementHeigh: 0 },
	setBreadcrumb: () => {},
	setMenuSelect: () => {},
	setMainScroll: () => {}
};

const LayoutContext = createContext<ILayoutContext>(initialLayoutContext);

const AUTH_SEGMENT: string[] = [
	AUTH_PATH.LOGIN,
	AUTH_PATH.INSTALL,
	AUTH_PATH.INSTALL_CALLBACK,
	AUTH_PATH.LOGIN_CALLBACK,
	EXCEPTION_PATH.MAIN,
	EXCEPTION_PATH.PERMISSION
];

const LayoutProvider = ({ children }: ILayoutContextType) => {
	const [breadcrumbs, _setBreadcrumb] = useState<BreadcrumbProps[]>(initialLayoutContext.breadcrumbs);
	const [menuSelected, _setMenu] = useState<NavItemType | null>(initialLayoutContext.menuSelected);
	const [isReadySideBar, setReadySidebar] = useState<boolean>(false);
	const [isReadySettingSideBar, setReadySettingSidebar] = useState<boolean>(false);
	const [isReadyBreadcrumb, setReadyBreadcrumb] = useState<boolean>(false);
	const [isReadyAvatarBox, setReadyAvatarBox] = useState<boolean>(false);
	const [mainScroll, _setMainScroll] = useState(initialLayoutContext.mainScroll);
	const isAlive = useAppSelector((state) => state.app.isAlive);
	const isReady = useAppSelector((state) => state.app.isReady);
	// const trigger = useAppDispatch();
	const isIdle = useIdle(APP_CONFIG.app_idle);
	const locationHook = useLocation();
	// const windowSizeHook = useWindowSize();

	// const isPortrait: boolean = useMemo(() => {
	// 	if (windowSizeHook) {
	// 		return windowSizeHook.width < windowSizeHook.height;
	// 	}
	// 	return false;
	// }, [windowSizeHook]);

	const loadAppResource = async () => {
		// await trigger(AuthActions.getLoginUrl());
		// await trigger(AuthActions.getInstallUrl());
		_getReady();
	};

	//#region  session
	const _checkSession = async () => {
		if (!AUTH_SEGMENT.includes(locationHook.pathname)) {
			// await trigger(AppActions.pingSession());
			if (!isReady) {
				// await trigger(AppActions.getSession());
			}
		}
	};

	//#endregion

	//#region menu
	const setBreadcrumb = (items: BreadcrumbProps[]) => {
		_setBreadcrumb([...items]);
	};

	const setMenuSelect = (menu: NavItemType) => {
		_setMenu(menu);
	};

	const _getReady = () => {
		window.setTimeout(() => {
			setReadySidebar(true);
			setReadySettingSidebar(true);
			setReadyBreadcrumb(true);
			setReadyAvatarBox(true);
		}, 300);
	};

	const _detectMenu = () => {
		if (locationHook) {
			const menuIDX = _.findIndex(MENU_ITEMS, (value) => value.url === locationHook.pathname);
			const menuSettingIDX = _.findIndex(SETTING_MENU, (value) => value.url === locationHook.pathname);
			if (menuIDX !== -1) {
				setMenuSelect(MENU_ITEMS[menuIDX]);
				setBreadcrumb(MENU_ITEMS[menuIDX].breadcrumbs ?? []);
			}
			if (menuSettingIDX !== -1) {
				const _item = MENU_ITEMS[MENU_ITEMS.length - 1];
				setMenuSelect(_item);
				setBreadcrumb(_item.breadcrumbs ?? []);
			}
		}
	};

	const setMainScroll = (scroll: { scrollHeight: number; scrollTopHeigh: number; elementHeigh: number }) => {
		_setMainScroll(scroll);
	};
	//#endregion

	//#region side effect
	useEffect(() => {
		_detectMenu();
		_checkSession();
	}, [locationHook.pathname]);

	useEffect(() => {
		_detectMenu();
		loadAppResource();
	}, []);

	//#endregion

	return (
		<LayoutContext.Provider
			value={{
				breadcrumbs,
				setBreadcrumb,
				menuSelected,
				setMenuSelect,
				mainScroll,
				setMainScroll,
				isReadySideBar,
				isReadySettingSideBar,
				isReadyBreadcrumb,
				isReadyAvatarBox
			}}
		>
			{META_ENV.env.DEV ? <TailwindIndicator /> : ""}
			{children}
			{isIdle && <h1>Timeout</h1>}
			{/* {isPortrait && <h1>Portrait</h1>} */}
			{isAlive && <h1>Alive</h1>}
			{isReady && <h1>Ready</h1>}
		</LayoutContext.Provider>
	);
};

export { LayoutContext, LayoutProvider };
