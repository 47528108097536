import React from "react";
import { Outlet } from "react-router-dom";
import { NavBar } from "./Headers";
import { MainBody } from "./Content";

export const AppLayout: React.FC = () => {
	return (
		<div className="flex min-h-screen w-full flex-col">
			<NavBar />
			<MainBody className="main-content">
				<Outlet />
			</MainBody>
		</div>
	);
};
