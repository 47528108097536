import React from "react";
import { AvatarBox, IAvatarBoxProps } from "./Avatar-Box";
import { LogoBox } from "./Logo-Box";

const NavBar: React.FC = () => {
	const avatar_box_props: IAvatarBoxProps = {
		user_name: "John Doe",
		first_name: "John",
		last_name: "Doe",
		email: "john.doe@example.com",
		login_url: "https://example.com/login"
	};

	return (
		<header className="sticky top-0 flex h-16 items-center gap-4 border-b  px-4 md:px-6 bg-chart-3 z-[1001]">
			<div className=" container mx-auto flex items-center justify-between space-x-4">
				<LogoBox />
				<div className="flex items-center space-x-4">
					<AvatarBox {...avatar_box_props} />
				</div>
			</div>
		</header>
	);
};

export default NavBar;
export { NavBar };
