import React from "react";
import { ErrorBoundary } from "react-error-boundary";

interface MainContentProps {
	children: React.ReactNode;
	className?: string;
}

const MainBody: React.FC<MainContentProps> = ({ children, className }) => {
	const onError = (error: Error, info: React.ErrorInfo) => {
		console.error(error);
		console.error(info);
	};

	return (
		<ErrorBoundary fallback={<div>Error</div>} onError={onError}>
			<main className={`container flex flex-1 flex-col gap-4 md:gap-8 md:p-4 xss:p-4  xl:mx-auto ${className ?? ""}`}>{children}</main>
		</ErrorBoundary>
	);
};

export default MainBody;
export { MainBody };
