import { Icons } from "@/components/icons/icon";
import { BreadcrumbProps } from "src/types";
import { APP_PATH } from "./root-path";

export const ORDER_BREADCRUMB: BreadcrumbProps[] = [
	{
		href: APP_PATH.ROOT_APP,
		title: "Đơn hàng",
		chip: null,
		disabled: false,
		icon: Icons.House
	}
];

export const BARCODE_BREADCRUMB: BreadcrumbProps[] = [
	{
		title: "Danh sách barcode",
		href: APP_PATH.ROOT_APP,
		chip: null,
		disabled: false,
		icon: Icons.House
	}
];

export const ADAPTER_BREADCRUMB: BreadcrumbProps[] = [
	{
		title: "Thiết lập kết nối",
		href: APP_PATH.ROOT_APP,
		chip: null,
		disabled: false,
		icon: Icons.House
	}
];

export const SETTING_BREADCRUMB: BreadcrumbProps[] = [
	{
		title: "Cấu hình",
		href: APP_PATH.SETTING.MANGER,
		chip: null,
		disabled: false,
		icon: Icons.House
	}
];

export const USERLOG_BREADCRUMB: BreadcrumbProps[] = [
	{
		title: "Nhật ký",
		href: APP_PATH.USER_LOG.MANAGER,
		chip: null,
		disabled: false,
		icon: Icons.Logs
	}
];
