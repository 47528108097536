/* eslint-disable @typescript-eslint/no-unused-vars */
import { NavItemType } from "src/types";
import { Icons } from "@/components/icons/icon";
import { APP_PATH } from "@/configs/root-path";
import { ORDER_BREADCRUMB, SETTING_BREADCRUMB, USERLOG_BREADCRUMB } from "@/configs/breadcrumb";

// ================== MENU-CODE ==================
// #region MENU-CODE
export const MENU_CODE = {
	ORDER: "order",
	BARCODE: "barcode",
	CONNECT: "connect",
	SETTING: "setting",
	USER_LOG: "user_log"
};
//#endregion

// ================== ADAPTER NAVIGATION ==================
//#region ADAPTER
const ShopeeNav: NavItemType = {
	caption: "Shopee",
	title: "Shopee",
	icon: null,
	url: APP_PATH.ROOT_HOME,
	type: "item",
	code: MENU_CODE.CONNECT,
	children: [],
	target: false,
	external: false,
	breadcrumbs: [],
	id: "menu-adapter-shopee-id",
	chip: null,
	disabled: false,
	hidden: false,
	level: 1
};

const TiktokNav: NavItemType = {
	caption: "Tiktok",
	title: "Tiktok",
	icon: null,
	url: APP_PATH.ROOT_HOME,
	type: "item",
	code: MENU_CODE.CONNECT,
	children: [],
	target: false,
	external: false,
	breadcrumbs: [],
	id: "menu-adapter-tiktok-id",
	chip: null,
	disabled: false,
	hidden: false,
	level: 1
};

const LazadaNav: NavItemType = {
	caption: "Lazada",
	title: "Lazada",
	icon: null,
	url: APP_PATH.ROOT_HOME,
	type: "item",
	code: MENU_CODE.CONNECT,
	children: [],
	target: false,
	external: false,
	breadcrumbs: [],
	id: "menu-adapter-lazada-id",
	chip: null,
	disabled: false,
	hidden: false,
	level: 1
};

const TikiNav: NavItemType = {
	caption: "Tiki",
	title: "Tiki",
	icon: null,
	url: APP_PATH.ROOT_HOME,
	type: "item",
	code: MENU_CODE.CONNECT,
	children: [],
	target: false,
	external: false,
	breadcrumbs: [],
	id: "menu-adapter-tiki-id",
	chip: null,
	disabled: false,
	hidden: false,
	level: 1
};

export const ADAPTER_MENU = [ShopeeNav, TiktokNav, LazadaNav, TikiNav];

//#endregion

// ================== MENU NAVIGATION ==================
//#region MAIN

const OrderNav: NavItemType = {
	caption: "Danh sách đơn hàng",
	title: "Danh sách đơn hàng",
	icon: Icons.ShoppingCart,
	url: APP_PATH.ROOT_HOME,
	type: "item",
	code: MENU_CODE.ORDER,
	children: [],
	target: false,
	external: false,
	breadcrumbs: ORDER_BREADCRUMB,
	id: "menu-order-manager-id",
	chip: null,
	disabled: false,
	level: 1,
	sort: 1
};

const UserLogNav: NavItemType = {
	caption: "Nhật ký hoạt động",
	title: "Nhật ký hoạt động",
	icon: Icons.Logs,
	url: APP_PATH.USER_LOG.MANAGER,
	type: "item",
	code: MENU_CODE.USER_LOG,
	children: [],
	target: false,
	external: false,
	breadcrumbs: USERLOG_BREADCRUMB,
	id: "menu-user-log-id",
	chip: null,
	disabled: false,
	level: 1,
	sort: 6
};

const SettingNav: NavItemType = {
	caption: "Cấu hình",
	title: "Cấu hình",
	icon: Icons.Settings,
	url: APP_PATH.SETTING.MANGER,
	type: "item",
	code: MENU_CODE.SETTING,
	children: [],
	target: false,
	external: false,
	breadcrumbs: SETTING_BREADCRUMB,
	id: "menu-setting-id",
	chip: null,
	disabled: false,
	level: 1,
	sort: 5
};

export const MENU_ITEMS: Array<NavItemType> = [UserLogNav, SettingNav];

export const FOOTER_MENU: NavItemType = SettingNav;
export const FIRST_MENU: NavItemType = OrderNav;
//#endregion

// ================== SETTING NAVIGATION ==================
//#region SETTING
const ShopSettingNav: NavItemType = {
	caption: "Cửa hàng",
	title: "Cửa hàng",
	icon: Icons.Store,
	url: APP_PATH.SETTING.SHOP,
	type: "item",
	children: [],
	target: false,
	external: false,
	breadcrumbs: [],
	id: "menu-shop-setting-id",
	chip: null,
	disabled: false,
	level: 1
};

const OrderSettingNav: NavItemType = {
	caption: "Đơn hàng",
	title: "Đơn hàng",
	icon: Icons.ShoppingCart,
	url: APP_PATH.SETTING.ORDER,
	type: "item",
	children: [],
	target: false,
	external: false,
	breadcrumbs: [],
	id: "menu-order-setting-id",
	chip: null,
	disabled: false,
	level: 1
};

const NotifySettingNav: NavItemType = {
	caption: "Thông báo",
	title: "Thông báo",
	icon: Icons.Bell,
	url: APP_PATH.SETTING.NOTIFY,
	type: "item",
	children: [],
	target: false,
	external: false,
	breadcrumbs: [],
	id: "menu-notify-setting-id",
	chip: null,
	disabled: false,
	level: 1
};

export const SETTING_MENU = [ShopSettingNav, OrderSettingNav, NotifySettingNav];
//#endregion
