import {
	Origami,
	Menu,
	CircleUser,
	ArrowLeftToLine,
	Smile,
	Calculator,
	User,
	CreditCard,
	Cog,
	Calendar,
	LogInIcon,
	Logs,
	Send,
	ExternalLink,
	MailCheck,
	RotateCw,
	Sparkles,
	ArrowRight,
	ChevronDown,
	ChevronUp,
	LayoutGrid,
	AreaChart,
	NotebookText,
	Settings2,
	Settings,
	UserCheck,
	UserCheck2,
	PanelLeft,
	Moon,
	Sun,
	SquareX,
	Building,
	Building2,
	ArrowDownUp,
	LogIn,
	LogOut,
	Undo2,
	MailCheckIcon,
	SendIcon,
	RectangleEllipsis,
	RotateCcw,
	Plus,
	SettingsIcon,
	FileClock,
	Barcode,
	Store,
	UserCog,
	Users,
	UserRound,
	ShoppingCart,
	Bell,
	Import,
	Download,
	RefreshCcw,
	Phone,
	Workflow,
	ArrowBigLeft,
	PackageCheck,
	CheckCheck,
	Repeat,
	ArrowLeftRight,
	House,
	Folder,
	File,
	FilePlus,
	Save,
	SaveAll,
	SaveOff,
	Trash,
	Trash2,
	ArrowUp,
	ArrowUp10,
	ArrowUpSquare,
	Info,
	X,
	Globe,
	Lock,
	HelpCircle,
	TicketPlus,
	TicketMinus,
	ArrowUpWideNarrow,
	ArrowDownWideNarrow,
	TrendingUp,
	TrendingDown,
	PiggyBank,
	EllipsisVertical
} from "lucide-react";

export const LUCIDE_REACT_ICONS = {
	Origami,
	Menu,
	CircleUser,
	ArrowLeftToLine,
	Smile,
	Calculator,
	User,
	CreditCard,
	Cog,
	Calendar,
	LogInIcon,
	Send,
	ExternalLink,
	MailCheck,
	RotateCw,
	Sparkles,
	ArrowRight,
	ChevronDown,
	ChevronUp,
	LayoutGrid,
	AreaChart,
	NotebookText,
	Settings2,
	Settings,
	UserCheck,
	UserCheck2,
	PanelLeft,
	Moon,
	Sun,
	SquareX,
	Building,
	Building2,
	ArrowDownUp,
	LogIn,
	LogOut,
	Undo2,
	MailCheckIcon,
	SendIcon,
	RectangleEllipsis,
	RotateCcw,
	Plus,
	SettingsIcon,
	FileClock,
	Barcode,
	Store,
	UserCog,
	Users,
	UserRound,
	ShoppingCart,
	Bell,
	Import,
	Download,
	RefreshCcw,
	Phone,
	Workflow,
	ArrowBigLeft,
	PackageCheck,
	CheckCheck,
	Repeat,
	ArrowLeftRight,
	House,
	Folder,
	File,
	FilePlus,
	Logs,
	Save,
	SaveAll,
	SaveOff,
	Trash,
	Trash2,
	ArrowUp,
	ArrowUp10,
	ArrowUpSquare,
	Info,
	X,
	Globe,
	Lock,
	HelpCircle,
	TicketPlus,
	TicketMinus,
	ArrowUpWideNarrow,
	ArrowDownWideNarrow,
	TrendingUp,
	TrendingDown,
	PiggyBank,
	EllipsisVertical
};
