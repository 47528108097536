import { LedgerReducer, actions, name } from "./ledger.slice";
import { getListLedger, getLedger, addNewLedger, enableLedger, disableLedger } from "./ledger.action";

const LedgerActions = {
	...actions,
	getListLedger,
	getLedger,
	addNewLedger,
	enableLedger,
	disableLedger
};

export { name, LedgerReducer, LedgerActions };
