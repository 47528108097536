import React from "react";
import { Outlet } from "react-router-dom";

export const MinimalLayout: React.FC = () => {
	return (
		<div className="flex justify-center items-center h-screen">
			{/* <div className="bg-blue-500 p-4 rounded-md">
        <h1>Centered Content</h1>
        </div> */}
			{/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
			<Outlet />
		</div>
	);
};
