/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteObject } from "react-router-dom";
import { AUTH_PATH, APP_PATH } from "@/configs";
import Login from "@/screens/auth/login.container";

// const Login = React.lazy(() => import("@/screens/auth/login.container"));

export const AuthRoutes: RouteObject[] = [
	{ path: APP_PATH.ROOT_AUTH, index: true, element: <Login /> },
	{ path: AUTH_PATH.LOGIN, element: <Login /> }
];
