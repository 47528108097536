import React, { useState } from "react";
import { Icons } from "@/components/icons/icon";
// import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

export interface IAvatarBoxProps {
	user_name: string;
	first_name: string;
	last_name: string;
	email: string;
	login_url: string;
}

const AvatarBox: React.FC<IAvatarBoxProps> = ({ user_name, first_name, last_name, email, login_url }) => {
	const [language, setLanguage] = useState("English");
	// const [activeModal, setActiveModal] = useState(null);

	const avatarActions = [
		{ name: "Profile", icon: Icons.User, short_cut: "⌘P", is_separator: false },
		{ name: "Language", icon: Icons.Globe, short_cut: "⌘L", is_separator: false },
		{ name: "Privacy and Security", icon: Icons.Lock, short_cut: "⌘P", is_separator: false },
		{ name: "Setting", icon: Icons.Settings, short_cut: "⌘S", is_separator: false },
		{ name: "Help and Support", icon: Icons.HelpCircle, short_cut: null, is_separator: false },
		{ name: "Logout", icon: Icons.LogOut, short_cut: null, is_separator: true }
	];

	const handleAvatarAction = (action: any) => {
		if (action.name === "Language") {
			setLanguage(language === "English" ? "Vietnamese" : "English");
		} else if (action.name === "Logout") {
			console.log("Logging out...");
		} else {
			window.alert(action.name);
			console.log(email);
			console.log(login_url);

			// setActiveModal(action.name);
		}
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Avatar className="cursor-pointer">
					<AvatarImage src="https://github.com/shadcn.png1" alt="@shadcn" />
					<AvatarFallback>{last_name.charAt(0) + first_name.charAt(0)}</AvatarFallback>
				</Avatar>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56">
				<DropdownMenuLabel>{user_name}</DropdownMenuLabel>
				<DropdownMenuSeparator />
				{avatarActions.map((action) => (
					<>
						{action.is_separator && <DropdownMenuSeparator />}
						<DropdownMenuGroup>
							<DropdownMenuItem key={action.name} onClick={() => handleAvatarAction(action)}>
								<action.icon className="mr-2 h-4 w-4" />
								<span>{action.name}</span>
								{action.short_cut && <DropdownMenuShortcut>{action.short_cut}</DropdownMenuShortcut>}
							</DropdownMenuItem>
						</DropdownMenuGroup>
					</>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default AvatarBox;
export { AvatarBox };
