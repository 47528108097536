import { useContext } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import type { RootState, AppDispatch, AppStore } from "./store";
import { LayoutContext } from "@/contexts/layout.context";
/**
 *  doc https:streamich.github.io/react-use/?path=/story/sensors-useidle--docs
 * Collection hook
 **/
export * from "react-use";
export { useNavigate, useLocation, useParams };
// ! Use throughout the app instead of plain `useDispatch` and `useSelector`
// redux hook
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();
// context hook
export const useLayout = () => useContext(LayoutContext);
