import { GetLedgerRequest, GetListLedgerRequest, GetListLedgerResponse, PostLedgerRequest, PostLedgerResponse } from "./types";
import { handleErrorResponse } from "../../api.support";
import { OPERATION_HAPPY_MONEY } from "../happy-money.constant";
import { AXIOS_HAPPY_MONEY } from "../happy-money.base";
import { DataType, Ledger, QueryParams } from "@/types";

async function get(request: GetLedgerRequest): Promise<Ledger> {
	try {
		if (!request.id) throw new Error("Ledger ID is required");
		const response = await AXIOS_HAPPY_MONEY.GET<Ledger>({
			operation: OPERATION_HAPPY_MONEY.GET_DETAIL_LEDGER,
			urlIds: {
				ledger_id: request.id
			}
		});
		return response.data;
	} catch (error) {
		return handleErrorResponse(error);
	}
}

async function getList(request: GetListLedgerRequest): Promise<GetListLedgerResponse> {
	try {
		const query: QueryParams = {};
		if (request.page_number) query.page_number = request.page_number;
		if (request.page_size) query.page_size = request.page_size;
		if (request.status) query.status = request.status;
		if (request.name) query.name = request.name;
		const response = await AXIOS_HAPPY_MONEY.GET<GetListLedgerResponse>({
			operation: OPERATION_HAPPY_MONEY.GET_LIST_LEDGER,
			query: query,
			urlIds: {}
		});
		console.log(response.data);
		return response.data;
	} catch (error) {
		return handleErrorResponse(error);
	}
}

async function create(request: PostLedgerRequest): Promise<string> {
	try {
		if (!request.name) throw new Error("Name is required");
		const response = await AXIOS_HAPPY_MONEY.POST<PostLedgerResponse>({
			operation: OPERATION_HAPPY_MONEY.ADD_NEW_LEDGER,
			data: { ...request },
			urlIds: {},
			type: DataType.JSON
		});
		return response.data.id;
	} catch (error) {
		return handleErrorResponse(error);
	}
}

async function enable(request: GetLedgerRequest): Promise<boolean> {
	try {
		const response = await AXIOS_HAPPY_MONEY.PUT<boolean>({
			operation: OPERATION_HAPPY_MONEY.ENABLE_LEDGER,
			data: {},
			urlIds: {
				ledger_id: request.id
			},
			type: DataType.JSON
		});
		return response.data;
	} catch (error) {
		return handleErrorResponse(error);
	}
}

async function disable(request: GetLedgerRequest): Promise<boolean> {
	try {
		const response = await AXIOS_HAPPY_MONEY.PUT<boolean>({
			operation: OPERATION_HAPPY_MONEY.DISABLE_LEDGER,
			data: {},
			urlIds: {
				ledger_id: request.id
			},
			type: DataType.JSON
		});
		return response.data;
	} catch (error) {
		return handleErrorResponse(error);
	}
}

export const LedgerAPI = {
	get,
	getList,
	create,
	enable,
	disable
};
