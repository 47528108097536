import { Method, ResourcePath } from "@/types";

export const HM_API_TIMEOUT = 10000;

//#region admin happ money path
export enum OPERATION_HAPPY_MONEY {
	// common
	LOGIN = "login",
	LOGOUT = "logout",
	GET_SESSION = "get_session",
	CHECK_LIFE_CYCLE = "check_life_cycle",
	// user-setting
	GET_USER_SETTING = "get_user_setting",
	UPDATE_USER_SETTING = "update_user_setting",
	// ledger
	GET_LIST_LEDGER = "get_list_ledger",
	ADD_NEW_LEDGER = "add_new_ledger",
	UPDATE_LEDGER = "update_ledger",
	DELETE_LEDGER = "delete_ledger",
	GET_DETAIL_LEDGER = "get_detail_ledger",
	ENABLE_LEDGER = "enable_ledger",
	DISABLE_LEDGER = "disable_ledger",
	GET_TICKET_NUMBER_LEDGER = "get_ticket_number_ledger",
	// counter
	INIT_COUNTER = "init_counter",
	GET_NEXT_COUNTER = "get_next_counter",
	// category
	INIT_CATEGORY = "init_category",
	GET_LIST_CATEGORY = "get_list_category",
	GET_CHILDREN_CATEGORY = "get_children_category",
	CREATE_CATEGORY = "create_category",
	UPDATE_CATEGORY = "update_category",
	// ticket
	GET_LIST_TICKET = "get_list_ticket",
	UPDATE_TICKET = "update_ticket",
	DELETE_TICKET = "delete_ticket",
	GET_DETAIL_TICKET = "get_detail_ticket",
	CREATE_TICKET = "create_ticket"
}

const AUTH_PATH: ResourcePath[] = [
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.LOGIN,
		ids: [],
		path: "/api/admin/login"
	},
	{
		http_method: Method.Post,
		operation: OPERATION_HAPPY_MONEY.LOGOUT,
		ids: [],
		path: "/api/admin/logout"
	}
];

const SESSION_PATH: ResourcePath[] = [
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_SESSION,
		ids: [],
		path: "/api/admin/session"
	},
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.CHECK_LIFE_CYCLE,
		ids: [],
		path: "/api/admin/session/lifecycle"
	}
];

const LEDGER_PATH: ResourcePath[] = [
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_LIST_LEDGER,
		ids: [],
		path: "/api/admin/ledgers"
	},
	{
		http_method: Method.Post,
		operation: OPERATION_HAPPY_MONEY.ADD_NEW_LEDGER,
		ids: [],
		path: "/api/admin/ledgers"
	},
	{
		http_method: Method.Put,
		operation: OPERATION_HAPPY_MONEY.UPDATE_LEDGER,
		ids: ["ledger_id"],
		path: "/api/admin/ledgers/<ledger_id>"
	},
	{
		http_method: Method.Delete,
		operation: OPERATION_HAPPY_MONEY.DELETE_LEDGER,
		ids: ["ledger_id"],
		path: "/api/admin/ledgers/<ledger_id>"
	},

	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_DETAIL_LEDGER,
		ids: ["ledger_id"],
		path: "/api/admin/ledgers/<ledger_id>"
	},
	{
		http_method: Method.Put,
		operation: OPERATION_HAPPY_MONEY.ENABLE_LEDGER,
		ids: ["ledger_id"],
		path: "/api/admin/ledgers/<ledger_id>/enable"
	},
	{
		http_method: Method.Put,
		operation: OPERATION_HAPPY_MONEY.DISABLE_LEDGER,
		ids: ["ledger_id"],
		path: "/api/admin/ledgers/<ledger_id>/disable"
	},
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_TICKET_NUMBER_LEDGER,
		ids: ["ledger_id"],
		path: "/api/admin/ledgers/<ledger_id>/ticket-number"
	}
];

const CATEGORY_PATH: ResourcePath[] = [
	{
		http_method: Method.Post,
		operation: OPERATION_HAPPY_MONEY.INIT_CATEGORY,
		ids: [],
		path: "/api/admin/categories/init"
	},
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_LIST_CATEGORY,
		ids: [],
		path: "/api/admin/categories"
	},
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_CHILDREN_CATEGORY,
		ids: [],
		path: "/api/admin/categories/children"
	},
	{
		http_method: Method.Post,
		operation: OPERATION_HAPPY_MONEY.CREATE_CATEGORY,
		ids: [],
		path: "/api/admin/categories"
	},
	{
		http_method: Method.Put,
		operation: OPERATION_HAPPY_MONEY.UPDATE_CATEGORY,
		ids: ["category_id"],
		path: "/api/admin/categories/<category_id>"
	}
];

const TICKET_PATH: ResourcePath[] = [
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_LIST_TICKET,
		ids: [],
		path: "/api/admin/tickets"
	},
	{
		http_method: Method.Post,
		operation: OPERATION_HAPPY_MONEY.CREATE_TICKET,
		ids: [],
		path: "/api/admin/tickets"
	},
	{
		http_method: Method.Delete,
		operation: OPERATION_HAPPY_MONEY.DELETE_TICKET,
		ids: ["ticket_id"],
		path: "/api/admin/tickets/<ticket_id>"
	},
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_DETAIL_TICKET,
		ids: ["ticket_id"],
		path: "/api/admin/tickets/<ticket_id>"
	},
	{
		http_method: Method.Put,
		operation: OPERATION_HAPPY_MONEY.UPDATE_TICKET,
		ids: ["ticket_id"],
		path: "/api/admin/tickets/<ticket_id>"
	}
];

const SETTING_PATH: ResourcePath[] = [
	{
		http_method: Method.Get,
		operation: OPERATION_HAPPY_MONEY.GET_USER_SETTING,
		ids: [],
		path: "/api/admin/settings"
	},
	{
		http_method: Method.Put,
		operation: OPERATION_HAPPY_MONEY.UPDATE_USER_SETTING,
		ids: ["setting_type"],
		path: "/api/admin/settings/<setting_type>"
	}
];

const HAPPY_MONEY_V2024_API_PATHS: ResourcePath[] = [
	...AUTH_PATH,
	...SESSION_PATH,
	...LEDGER_PATH,
	...CATEGORY_PATH,
	...TICKET_PATH,
	...SETTING_PATH
];
//#endregion

export const HAPPY_MONEY_API_PATHS: ResourcePath[] = [...HAPPY_MONEY_V2024_API_PATHS];
