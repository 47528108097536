import { combineReducers } from "redux";
import { AppReducer } from "@/features/app";
import { LedgerReducer } from "@/features/ledger";
// import { AuthReducer } from "src/features/auth";
// import { SettingReducer } from "src/features/setting";
// combine reducers
const reducers = combineReducers({
	app: AppReducer,
	ledger: LedgerReducer
	// auth: AuthReducer,
	// setting: SettingReducer
});

export default reducers;
