import { ConfigType } from "src/types";

export const APP_CONFIG: ConfigType = {
	app_idle: 30 * 60 * 1000 // };
};

export const NumberShowType = {
	// số nguyên
	integer: "int",
	// số có lẻ sau chấm thập phân
	decimal: "dec",
	// tương đối
	relative: "relative",
	// theo custom format
	custom: "custom"
};
export const CurrencyShowType = {
	// hiển thị số
	absolute: "absolute",
	// hiển thị tương đối
	relative: "relative",
	// theo custom format
	custom: "custom"
};
export const PercentShowType = {
	// số nguyên
	integer: "int",
	// có lẻ sau chấm thập phân
	decimal: "dec",
	// theo custom format
	custom: "custom"
};

export const DateTimeShowType = {
	// follow moment's relativeTime
	relative: "relative",
	// Day of Week
	day_of_week: "day_of_week",
	// HH:mm A
	time: "time",
	// HH:mm:ss A
	long_time: "long_time",
	// DD/MM/YYYY
	date: "date",
	// follow moment's calendar
	long_date: "long_date",
	// relative within an hour, above 1 hour: long_date
	mixed_long_date: "mixed_long_date",
	// DD/MM/YYYY HH:mm:ss
	date_time: "date_time",
	// custom
	custom: "custom"
};

export const TIMEZONE = 7;
