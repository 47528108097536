/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "lodash";

export class LocalStorage {
	public static set(key: string, value: any): any {
		try {
			this.checkAvailable(key);
			// eslint-disable-next-line valid-typeof, no-constant-binary-expression
			if (typeof value === "undefined" || typeof value === null) return null;
			window.localStorage.setItem(key, JSON.stringify(value));
		} catch (err: any) {
			return false;
		}
	}

	public static get(key: string): any {
		try {
			this.checkAvailable(key);
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : null;
		} catch (err: any) {
			return false;
		}
	}

	public static delete(key: string): any {
		try {
			this.checkAvailable(key);
			return window.localStorage.removeItem(key);
		} catch (err: any) {
			return false;
		}
	}

	private static checkAvailable(key: string) {
		if (typeof window === "undefined") {
			return null;
		}

		if (_.isEmpty(key)) {
			return null;
		}
	}
}
