import { PayloadAction, createSlice } from "@reduxjs/toolkit";
//internal import
import { AppState, LifeCycleResponse, SessionInfoResponse } from "@/types/app.type";
import { APP_REDUCER } from "./app.constant";
import { pingSession, getSession } from "./app.action";
import { LocalStorage } from "@/lib/local-storage";
import { META_ENV } from "@/configs";

export const initialState: AppState = {
	isAppLoading: false,
	isReady: false,
	isAlive: false,
	prefix: "",
	title: "",
	showMenu: [],
	shop: {
		orgID: -1,
		domain: "",
		logoutURL: "",
		shopName: ""
	},
	user: {
		_id: -1,
		email: "",
		firstName: "",
		lastName: "",
		roles: []
	}
};

const appSlice = createSlice({
	name: APP_REDUCER,
	initialState: initialState,
	reducers: {
		set_is_alive: (state, { payload }: PayloadAction<boolean>) => {
			state.isAlive = payload;
		},
		set_app_ready: (state, { payload }: PayloadAction<boolean>) => {
			state.isReady = payload;
		},
		set_app_loading: (state, { payload }: PayloadAction<boolean>) => {
			state.isAppLoading = payload;
		}
	},
	extraReducers(builder) {
		// ================== SESSION ==================
		builder.addCase(getSession.fulfilled, (state, { payload }: PayloadAction<SessionInfoResponse | null>) => {
			state.isReady = true;
			if (!payload) return;
			const { session } = payload;
			const tempMenu: string[] = [];
			const _fullName = session.name.split(" ");
			state.shop.orgID = Number(session.orgid);
			state.shop.shopName = session.orgname;
			state.shop.domain = session.domain;
			state.user._id = Number(session.userid);
			state.user.firstName = _fullName[0] ? _fullName[0] : "";
			state.user.lastName = _fullName[1] ? _fullName[1] : "";
			state.user.email = session.email;
			state.user.roles = session.role;
			for (const menu of Object.keys(session.menu ?? [])) {
				if (session.menu[menu as keyof typeof session.menu]) {
					tempMenu.push(menu);
				}
			}
			state.showMenu = [...tempMenu];
		});
		builder.addCase(getSession.rejected, (state) => {
			state.shop.orgID = -1;
			state.shop.shopName = "";
			state.shop.domain = "";
			state.user._id = -1;
			state.user.firstName = "";
			state.user.lastName = "";
			state.user.email = "";
			state.user.roles = [];
			state.isReady = true;
			state.showMenu = [];
		});
		builder.addCase(pingSession.fulfilled, (state, { payload }: PayloadAction<LifeCycleResponse>) => {
			if (payload) {
				state.isAlive = payload.isLive;
				if (!payload.isLive) LocalStorage.delete(META_ENV.env.VITE_APP_SID);
			} else {
				LocalStorage.delete(META_ENV.env.VITE_APP_SID);
				state.isAlive = false;
			}
			state.isReady = true;
		});
		builder.addCase(pingSession.rejected, (state) => {
			state.isAlive = false;
			state.isReady = true;
			LocalStorage.delete(META_ENV.env.VITE_APP_SID);
		});
	}
});

export const { actions, name, reducer: AppReducer } = appSlice;
