import { AppReducer, actions, name } from "./app.slice";
import { pingSession, getSession } from "./app.action";

const AppActions = {
	...actions,
	pingSession,
	getSession
};

export { name, AppReducer, AppActions };
