import { PayloadAction, createSlice } from "@reduxjs/toolkit";
//internal import
import { Ledger, LedgerState } from "@/types";
import { LEDGER_REDUCER } from "./ledger.constant";
import { getListLedger, getLedger, addNewLedger, enableLedger, disableLedger } from "./ledger.action";
import { GetListLedgerResponse } from "@/services";

export const initialState: LedgerState = {
	list_ledger: [],
	total_ledger: 0,
	has_more: false,
	selected_ledger_item: null,
	selected_ledger_id: "",
	ledger_loading_mode: {
		is_add: false,
		is_edit: false,
		is_list: true,
		is_detail: false
	}
};

const ledgerSlice = createSlice({
	name: LEDGER_REDUCER,
	initialState,
	reducers: {
		resetLedger: (state) => {
			state.selected_ledger_item = null;
			state.selected_ledger_id = "";
		},
		setIdLedger: (state, action: PayloadAction<string>) => {
			state.selected_ledger_id = action.payload;
		}
	},
	extraReducers: (builder) => {
		//get list
		builder.addCase(getListLedger.pending, (state) => {
			state.ledger_loading_mode.is_list = true;
		});

		builder.addCase(getListLedger.fulfilled, (state, { payload }: PayloadAction<GetListLedgerResponse>) => {
			state.list_ledger = payload.ledgers;
			state.total_ledger = payload.total;
			state.has_more = payload.has_more;
			state.ledger_loading_mode.is_list = false;
		});
		builder.addCase(getListLedger.rejected, (state) => {
			state.list_ledger = [];
			state.total_ledger = 0;
			state.has_more = false;
			state.ledger_loading_mode.is_list = false;
		});
		// get detail
		builder.addCase(getLedger.pending, (state) => {
			state.ledger_loading_mode.is_detail = true;
		});
		builder.addCase(getLedger.fulfilled, (state, { payload }: PayloadAction<Ledger>) => {
			if (payload._id) {
				state.selected_ledger_item = payload;
				state.selected_ledger_id = payload._id;
			}
			state.ledger_loading_mode.is_detail = false;
		});

		builder.addCase(getLedger.rejected, (state) => {
			state.selected_ledger_item = null;
			state.ledger_loading_mode.is_detail = false;
		});

		//  add new ledger
		builder.addCase(addNewLedger.pending, (state) => {
			state.ledger_loading_mode.is_add = true;
		});
		builder.addCase(addNewLedger.fulfilled, (state, { payload }: PayloadAction<string>) => {
			state.selected_ledger_id = payload;
			state.ledger_loading_mode.is_add = false;
		});
		builder.addCase(addNewLedger.rejected, (state) => {
			state.selected_ledger_id = "";
			state.ledger_loading_mode.is_add = false;
		});
		// enable
		builder.addCase(enableLedger.pending, (state) => {
			state.ledger_loading_mode.is_detail = true;
		});
		builder.addCase(enableLedger.fulfilled, (state) => {
			state.ledger_loading_mode.is_detail = false;
		});
		builder.addCase(enableLedger.rejected, (state) => {
			state.ledger_loading_mode.is_detail = false;
		});
		// disable
		builder.addCase(disableLedger.pending, (state) => {
			state.ledger_loading_mode.is_detail = true;
		});
		builder.addCase(disableLedger.fulfilled, (state) => {
			state.ledger_loading_mode.is_detail = false;
		});
		builder.addCase(disableLedger.rejected, (state) => {
			state.ledger_loading_mode.is_detail = false;
		});
	}
});

export const { actions, name, reducer: LedgerReducer } = ledgerSlice;
