const ROOT_PATH = {
	HOME: "/",
	APP: "/app",
	AUTH: "/auth"
};

// ================== SETTING ==================
const SETTING_PATH = {
	MANGER: `/setting`,
	COMMON: `/setting/common`,
	SHOP: `/setting/shop`,
	ORDER: `/setting/order`,
	NOTIFY: `/setting/notify`
};

// ================== LOGS ==================
const USER_LOG_PATH = {
	MANAGER: `/user_log`
};
// ================== LEDGER ==================
const LEDGER_PATH = {
	DETAIL: `/ledger/:ledger_id`
};

// ================== APP PATH ==================
export const APP_PATH = {
	ROOT_HOME: ROOT_PATH.HOME,
	ROOT_APP: ROOT_PATH.APP,
	ROOT_AUTH: ROOT_PATH.AUTH,
	SETTING: { ...SETTING_PATH },
	USER_LOG: { ...USER_LOG_PATH },
	LEDGER: { ...LEDGER_PATH }
};

export const AUTH_PATH = {
	LOGIN: `${ROOT_PATH.AUTH}/login`,
	LOGIN_CALLBACK: `${ROOT_PATH.AUTH}/login/callback`,
	INSTALL: `${ROOT_PATH.AUTH}/install`,
	INSTALL_CALLBACK: `${ROOT_PATH.AUTH}/install/callback`
};

export const EXCEPTION_PATH = {
	MAIN: "/exception",
	PERMISSION: "/exception/permission"
};

export const PARAM_ID = {
	SHOPEE_ID: "shopee_id",
	TIKTOK_ID: "tiktok_id",
	LAZADA_ID: "lazada_id",
	TIKI_ID: "tiki_id"
};
